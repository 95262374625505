function transformAvatarToUpdateModel(avatar) {
  return {
    name: {
      en: avatar.name.en,
      bg: avatar.name.bg
    },
    imagePath: avatar.imagePath,
    categories: avatar.categories,
    price: avatar.price,
    promoPrice: avatar.promoPrice,
    goldPrice: avatar.goldPrice,
    new: avatar.new
  };
}

export default {
  transformAvatarToUpdateModel
};
