import httpService from '../../../core/services/http.service';

const BASE_URL = '/admin/avatars';

function index(params) {
  return httpService.get(BASE_URL, params);
}

function get(id) {
  return httpService.get(`${BASE_URL}/${id}`);
}

function create(payload) {
  return httpService.post(BASE_URL, payload);
}

function update(id, payload) {
  return httpService.put(`${BASE_URL}/${id}`, payload);
}

function del(id) {
  return httpService.del(`${BASE_URL}/${id}`);
}

export default {
  index,
  get,
  create,
  update,
  del
};
