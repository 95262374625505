<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-title>
        <span v-if="this.avatar.id">
          {{ $t('avatars.editAvatar') }}
        </span>
        <span v-else>{{ $t('avatars.createAvatar') }}</span>
        <div class="flex-grow-1"></div>
        <v-btn depressed color="primary" to="/avatars">
          <v-icon>mdi-chevron-left</v-icon>
          <span>{{ $t('avatars.backToAvatars') }}</span>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <manage-avatar-form
          v-if="!loading"
          :editted-avatar="avatar"
        ></manage-avatar-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ManageAvatarForm from '../components/ManageAvatarForm';
import httpService from '../services/avatars.http.service';

export default {
  name: 'manage-avatars-page',
  components: {
    ManageAvatarForm
  },
  data() {
    return {
      avatar: {},
      loading: false
    };
  },
  async created() {
    const id = this.$route.params.id;

    if (id) {
      this.loading = true;
      const { result } = await httpService.get(id);

      this.avatar = result;
      this.loading = false;
    }
  }
};
</script>
