<template>
  <v-form ref="manageAvatarForm">
    <v-row>
      <v-col md="12" class="pb-0">
        <h3>{{ $t('common.name') }}</h3>
      </v-col>
      <v-col md="6" sm="12">
        <v-text-field
          solo
          v-model="avatar.name.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12">
        <v-text-field
          solo
          v-model="avatar.name.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="py-0">
        <h3>{{ $t('common.image') }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-text-field
          solo
          class="pa-0 my-0"
          v-model="avatar.imagePath"
          :rules="[rules.required]"
          :label="$t('common.image')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="py-0">
        <h3>{{ $t('common.categories') }}</h3>
      </v-col>
      <v-col md="6" sm="12">
        <v-select
          v-model="avatar.categories"
          :rules="[rules.required, rules.maxArrayLength]"
          :items="categories"
          item-text="name"
          item-value="id"
          multiple
          solo
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <h3>{{ $t('common.price') }}</h3>
        <v-text-field
          class="mt-2"
          solo
          v-model="avatar.price"
          type="number"
          :label="$t('common.price')"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <h3>{{ $t('common.promoPrice') }}</h3>
        <v-text-field
          class="mt-2"
          solo
          v-model="avatar.promoPrice"
          type="number"
          :label="$t('common.promoPrice')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="py-0">
        <h3>{{ $t('common.goldPrice') }}</h3>
      </v-col>
      <v-col md="6" sm="12" class="pb-0">
        <v-text-field
          solo
          v-model="avatar.goldPrice"
          type="number"
          :label="$t('common.goldPrice')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <v-checkbox
          class="mt-0"
          v-model="avatar.new"
          :label="$t('common.new')"
          color="primary"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="mr-4" @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import httpService from '../services/avatars.http.service';
import mapper from '../mappers/avatars.mapper';
import validators from '../../../core/validators';
import categoriesHttpService from '../../avatar-categories/services/avatar.categories.http.service';

const MIN_CATEGORIES = 1;
const MAX_CATEGORIES = 3;

export default {
  name: 'manage-avatar-form',
  props: {
    edittedAvatar: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      categories: [],
      avatar: {
        name: {
          en: '',
          bg: ''
        },
        imagePath: '',
        price: 0,
        promoPrice: '',
        goldPrice: 0,
        categories: [],
        new: true
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        maxArrayLength: validators.minMaxArrayLength(
          MIN_CATEGORIES,
          MAX_CATEGORIES,
          this.$i18n.t('avatars.maxCategoriesLength', {
            number: MAX_CATEGORIES
          })
        )
      }
    };
  },
  created() {
    this.loadCategories();

    if (this.edittedAvatar.id) {
      this.avatar = {
        ...this.edittedAvatar
      };
    }
  },
  methods: {
    async loadCategories() {
      const { result } = await categoriesHttpService.baseIndex();

      this.categories = result.map(c => ({
        id: c.id,
        name: c.name[this.currentUser.lang]
      }));
    },
    async save() {
      if (!this.$refs.manageAvatarForm.validate()) {
        return;
      }

      if (this.avatar.id) {
        await httpService.update(
          this.avatar.id,
          mapper.transformAvatarToUpdateModel(this.avatar)
        );

        this.$notify({
          type: 'success',
          text: this.$root.$t('avatars.updateSuccess')
        });
      } else {
        await httpService.create(this.avatar);

        this.$notify({
          type: 'success',
          text: this.$root.$t('avatars.createSuccess')
        });
      }

      this.$router.push({ name: 'avatars' });
    }
  }
};
</script>
